:root[color-mode="dark"] {
    --mainText: #{$afLightSnow};
    --secondaryText: #{$afSnow};
    --fadedText: #888;
    --unitText: #888;
    --icons: #eee;

    --appBackground: #{$afNavy};
    --appStatusBackground: #{$afDarkNavy};

    --inputBackground: #000;
    --inputBorder: #{$afInk};
    --inputText: #{$afLightSnow};
    --submitBackground: #{$afDarkBlueberry};
    --submitText: #fff;

    --appHeaderText: #{$afLightSnow};
    --appHeaderBackground: #{$afDarkNavy};
    --appHeaderBorder: #{$afInk};
    --appHeaderBoxShadow: #111;

    --menuBackground: #{$afDarkNavy};

    // General UI
    --modeIconColor: #{$afLightSnow};
    --weatherIconColor: #{$afLightSnow};
    --uiIconColor: #{$afLightSnow};
    --uiHoverBackground: #{$afBlueberry};
    --uiHoverColor: #{$afLightSnow};
    --uiActive: #{$afLightSnow};
    --uiExit: #{$afStrawberry};

    --cardBackground: #{$afDarkNavy};
    --cardBoxShadow: #111;
    --cardBorder: #999;
    --cardHeaderBorder: #{$afDarkSnow};
    --cardFooterBorder: #{$afDarkSnow};
    --cardFooterBackground: #{$afNavy};

    --tapHeaderHighlight: #{$afBlueberry};
    --tapRowHighlight: #{$afDarkInk};
    --overlay: #{$afDarkNavy};

    --buttonBackground: #{$afDarkNavy};
    --buttonBorder: #{$afInk};
    --buttonText: #{$afLightSnow};
    --buttonTextHover: #{$afDarkNavy};
    --buttonBackgroundHover: #888;
    --buttonShadow: #{$afDarkNavy};
    --buttonHighlight: #{$afDarkInk};
    --buttonDestroy: #{$afLightBlueberry};
    --exitButtonBackground: #{$afBlueberry};

    --toggleBackground: #{$afDarkSnow};

    // External App Buttons
    --appButtonText: #{$afLightSnow};
    --appButtonBorder: #{$afLightSnow};
    --appButtonTextHover: #fff;
    --appButtonBackgroundHover: #{$afBlueberry};

    --navBackground: #{$afDarkNavy};
    --navBorder: #{$afInk};
    --navBoxShadow: #111;
    --navButton: #{$afLightSnow};
    --navButtonActiveText: #{$afLightSnow};
    --navButtonActive: #{$afLightSnow};
    --navButtonActiveBackground: #{$afLightSnow};
    --navButtonAltBg: #{$afBlueberry};

    // Map
    --mapMarkerBoxShadow: rgba(255, 255, 255, 0.5);
}