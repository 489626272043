.TripPlanContent {
    h4 {
        margin-bottom: 0.5em;
    }

    .route-indicator {
        vertical-align: middle;
        display: inline-block;
        width: 6px;
        height: 20px;
        margin-left: 0.2em;
        margin-right: 0.2em;
        border-radius: 20px;
        background: #ccc; // placeholder color
    }

    .TripOption {
        button {
            border: none;
        }
    }

    .directions {
        background: var(--cardBackground);
        padding: 0.2em;
        box-shadow: 0 0.1em 0.3em var(--cardBoxShadow);
        border-radius: 0.5em;
        margin-bottom: 1.5em;
    }

    &.TripPlanContentStepBased {
        .StepList .StepList {
            margin-left: 2em;

            .step-info-column {
                padding: 0.5em 0 0.5em 0;
            }
        }
    }
}