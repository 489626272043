.LocationSearchDropdown {
    opacity: 0;
    height: 0;
    position: absolute;
    z-index: 1; // Relative to parent
    top: 44px;
    width: 100%;
    color: var(--mainText);
    background: var(--inputBackground);
    box-shadow: 0 0.3em 0.5em var(--appHeaderBoxShadow);
    overflow: scroll;
    &.active {
        height: 500px;
        opacity: 1;
    }
    .Result {
        .select-item {
            border-bottom: 1px solid var(--appHeaderBorder);
            display: block;
            width: 100%;
            text-align: left;
            padding: 1em;
            .select-item-address {
                font-size: 0.8em;
                color: var(--secondaryText);
            }
        }
    }
}
