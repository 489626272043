.TripSearchScreen {
    // Limit width for responsive design
    margin: auto;
    max-width: 500px;

    background: var(--appBackground);
    min-height: 95vh; // gives some scroll bounce when no trip options

    .search-area {
        background: var(--appHeaderBackground);
        color: $afOffWhite;
        padding-bottom: 1em;

        .advanced {
            display: block;
            overflow: hidden;

            button {
                display: block;
                float: right;
                padding: 1em;
                min-width: 30%;
                color: var(--fadedText);
                background: none;
                text-align: right;
                font-size: 0.8em;
                border-radius: 1em;
                margin-right: 0.5em;
            }
        }

    }

    .header {
        padding: 1.2em 0 0.5em 3em;

        h1 {
            font-size: 1em;
            font-weight: 600;
        }

        span {
            position: absolute;
            right: 0;
            top: 0;
            color: #aaa;
        }
    }

    .status-message {
        font-size: 1.2em;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .loading-animation {
            overflow: hidden;
            width: 0;
            height: 0;
            transition: all 350ms ease-out;
        }

        &.visible {
            .text {
                padding: 1em;
            }

            .loading-animation {
                width: 15em;
                height: 15em;
            }
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(720deg);
            }
        }
    }

    .locations {
        display: flex;

        .waypoints {
            flex: 0 0 92%;
        }

        .switcher {
            flex: 0 1 8%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                padding: 0.25em;
            }

            svg {
                padding-top: 1em;
                width: 1.5em;
                height: 1.5em;

                * {
                    stroke: #eee;
                }

            }
        }
    }

    .parameters {
        margin: 0.5em 1em 1em 1em;
        display: flex;

        input {
            border: 1px solid var(--inputBorder);
            color: var(--inputText);
            background: var(--inputBackground);
            font: inherit;
        }

        .date {
            flex: 1 0 7.5em;
            margin-right: 0.5em;
        }

        .time {
            flex: 1 0 6.5em;
            margin-right: 0.5em;
        }

        .submit {
            flex: 1 1 6em;
            font-size: 1.3em;
            margin: auto;
            font-weight: bold;
            color: var(--submitText);
            background: var(--submitBackground);
            border-radius: 0.3em;
        }
    }

    .icons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 8px;

        .trip-line {
            margin-left: 1.1em;
            margin-top: -8px;
            height: 20px;
            overflow: hidden;

            * {
                stroke: var(--fadedText);
            }
        }
    }

    .datetime-container {
        clear: both;
        overflow: hidden;
        transition: height 150ms linear;
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding-left: 0.55em; // left aligns with icons above it
    }

    .search-button {
        margin: 0 auto 0 auto;
        display: block;
        width: 82%;
        height: 3.2em;
        color: #000;
        background: $afOffWhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: bold;

        &:hover {
            background: var(--buttonBackgroundHover);
        }
    }
}