.AppStatus {
    text-align: center;
    position: absolute;
    left: 0;
    top: 15vh;
    width: 100%;
    height: auto;
    z-index: 900;

    display: none;
    opacity: 0;
    transition: opacity 200ms ease-out;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        font-size: 1.1em;

        .loading-animation {
            width: 300px;
            height: 300px;
        }

        >div {
            margin-top: 0.5em;
        }
    }

    .Debug {
        margin-top: 0.5em;
        font-size: 0.6em;
        overflow-wrap: break-word;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}