/* Lazily just take over the scroll view */

.SearchResults {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--appBackground);
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    h3 {
        margin: 0.5em;
    }

    .Content {
        width: 90%;
        margin: auto;
    }
}