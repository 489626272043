.AppCodePromo {
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding: 2em 1em;
    color: var(--mainText);
}

.AppCodePromo .content {
    line-height: 1.8em;
    font-size: 1em;
}

.AppCodePromo.collapsed {
    padding: 1em;
}

.AppCodePromo button {
    cursor: pointer;
    color: var(--buttonText);
    margin-top: 1em;
    border: 1px solid #E9632C;
    border-radius: 1.5em;
    background: none;
    display: inline-block;
    background: var(--buttonBackground);
    padding: 0.5em 1.2em 0.5em 1.2em;
    font: 200 95% / 1.5em var(--secondaryFontBold), Monospace, Courier;
}

.AppCodePromo button:hover {
    color: var(--appButtonTextHover);
    background: var(--appButtonBackgroundHover);
}

.AppCodePromo button.exit {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    width: 4em;
    height: 1.5em;
    border: 0;
    color: #222;
    font-size: 0.8em;
    background: #eee;
}

.AppCodePromo b {
    padding: 0.2em 0.4em 0.2em 0.6em;
    margin-left: 0.2em;
    color: var(--buttonText);
    background: var(--buttonBackground);
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    border: 1px solid #ccc;
    font: 800 1.2em / 1.5em var(--secondaryFontBold), Monospace, Courier;
}

.AppCodePromo img {
    float: left;
    width: auto;
    margin-right: 0.3em;
    height: 6em;
}
