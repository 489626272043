.CardMap {
    border-bottom: 1px solid #aaa;
    flex: 0 0 350px;
    overflow: hidden;

    .StopMarker {
        // Markers are centered so the width defines the container area for .Head .Pin to center within
        width: 3.5em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .Head {
            width: 2em;
            height: 2em;
            border-radius: 0.2em;
            // border: 1px solid #555;
            padding: 0.3em;
            text-align: center;
            position: relative;
            z-index: 5;
            flex-direction: column;
            background: #ccc; // Gets overriden
            box-shadow: 0 0.1em 0.3em var(--mapMarkerBoxShadow);

            .Logo {
                svg {
                    width: auto;
                    height: 2em;
                    * {
                        stroke: #fff;
                        fill: none;
                    }
                }
            }

            .VehicleCount {
                background: var(--appBackground);
                color: var(--mainText);
                font-size: 1.2em;
                border-radius: 2em;
                padding: 0 0.2em 0 0.2em;
            }
        }

        .Pin {
            position: relative;
            z-index: 4;
            margin-top: -0.5em;
            display: block;
            width: 2em;
            height: auto;
        }
    }

    .UserMarker {
        z-index: 500;

        .Bubble {
            width: 1em;
            height: 1em;
            background: #147EFB;
            border: 5px solid #fff;
            border-radius: 2em;
            box-shadow: 0 0 2em #555;
            z-index: 2;
            position: relative;
        }

        .BubbleBg {
            border: 1px solid #147EFB;
            background: #add8e6;
            width: 4em;
            height: 4em;
            opacity: 0.4;
            position: absolute;
            top: -1.25em;
            left: -1.25em;
            z-index: 1;
            border-radius: 4em;
        }
    }

    .Notice {
        font-size: 0.8em;
        width: 80px;
        position: absolute;
        z-index: 800;
        top: 0;
        left: -60%;
        text-align: center;
    }
}

.isMobileSafari {
    .CardMap {
        flex: 0 0 300px;
    }
}