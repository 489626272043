.AIResults {
    padding: 1em;
    box-shadow: 0 0.1em 0.3em var(--cardBoxShadow);
    background: var(--cardBackground);
    color: var(--cardText);
    border-radius: 0.5em;

    .AIResult {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        .Icon {
            flex: 0 1 auto;
            vertical-align: middle;
            margin-right: 0.5em;

            svg {
                padding-top: 0.2em;
                width: 1em;
                height: 1em;
            }
        }

        .text {
            flex: 0 1 auto;
            font-size: 0.8em;
        }
    }
}