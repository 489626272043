.Card.StaticTextCard {
    .Content {
        /* Display flex prevents content from overflowing */
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
        border-radius: 0;
        overflow: hidden;
        padding: 0;
        margin: auto;
        width: 90%;
    }

    .Text {
        width: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    iframe {
        width: 100% !important;
        border: 0;
        padding: 0;
        margin: 0;
    }

    h1 {
        margin: 0.5em 0 0.2em 0;
        font-size: 1.4em;
        font-family: var(--primaryFontBold);
    }

    h2 {
        margin: 0.5em 0 0.2em 0;
        font-size: 1.2em;
        font-family: var(--primaryFontBold);
    }

    h3 {
        margin: 0.5em 0 0.2em 0;
        font-size: 1.1em;
        font-family: var(--primaryFontBold);
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0.5em 0 0.5em 0;
    }

    td {
        border: 1px solid #ccc;
        padding: 0.5em;
    }
}