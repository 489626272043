.Card.StaticImageCard .Content {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: 1em;
}

.StaticImageCard img {
    width: 100%;
    height: auto;
}