.DateTimeSelector {
    display: flex;
    align-items: center;

    position: relative;
    padding-bottom: 1em;


    svg * {
        fill: #fff;
    }

    input {
        // All of this is fine tuned for Mobile Safari which differs greatly from desktop
        height: 35px;
        padding: 0 0.5em 0 0.5em;
        min-width: 80px;
        background: var(--inputBackground);
        border: 1px solid var(--inputBorder);
        color: var(--inputText);
        border-radius: 4px;
        text-align: center;
        font-size: inherit;
        font-family: var(--primaryFontRegular);
    }

    input[disabled] {
        background: var(--appBackground);
        border: none;
    }

    .icon {
        margin-right: 0.5em;
    }
}