.TabBar {
    position: fixed;

    // Needs to be below header and overlay
    z-index: 100;
    left: 0;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid var(--navBorder);

    &.top {
        top: 0;
        box-shadow: 0 0.2em 0.5em var(--navBoxShadow);

        &.visible {
            transition: top ease-in 400ms;
            top: 0;
        }

        /* Really important this sets the hidden position */
        &.hidden {
            transition: top ease-out 800ms;
            top: -100px;
        }

        .TabBarControls {
            .ModeFilterButtons {}

            .TripPlanButton {}
        }

        .selected-indicator {
            top: 0;
            bottom: unset;
            border-radius: 0 0 5px 5px;
        }
    }

    &.bottom {
        bottom: 0;

        &.visible {
            transition: bottom ease-out 400ms;
            bottom: 0;
        }

        &.hidden {
            transition: bottom ease-in 800ms;
            bottom: -100px;
        }
    }

    .TabBarControls {
        height: 100%;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        // These wrappers are the background of the buttons
        .ModeFilterButtons {
            flex: 1 1 80%;
            height: 80px; // Match .TabBar
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--navBackground);
            overflow: hidden;
        }

        .TripPlanButton {
            flex: 0 1 18%;
            height: 80px; // Match .TabBar
            background: var(--navBackground);
            overflow: hidden;
        }
    }

    .bottom-spacer {
        width: 100%;
        height: 0px;
        background: var(--navBackground);
        margin-top: -1px; // Hides weird gap
        padding-bottom: 1px;
    }
}