.CardRowLeft {
    flex: 0 0 25%;
    align-items: center;
    position: relative;
    font-family: var(--primaryFontBold);
    font-weight: 500;
    .CardRowInner {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .AgencyLogo {
        flex: 0 0 auto;
        margin-right: 0.5em;
    }
    .AgencyLogo img {
        margin-top: 5px;
        width: auto;
        height: 1.2em;
    }
    .VehicleName {
        font-size: 1.2em;
        margin-right: 0.2em;
        letter-spacing: -0.5px;
    }
    .VehicleType {
        font-size: 1.1em;
        margin-right: 0.2em;
    }
    .VehicleCount {
        font-size: 1.2em;
        margin-right: 0.2em;
    }
    .VehicleCountLabel {
        font-size: 0.8em;
    }
}

.mode-floating {
    .CardRowLeft {
        .VehicleName {
            font-size: 1em;
            font-family: var(--secondaryFontMedium);
            font-weight: 500;
        }
    }
}
