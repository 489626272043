.CardFooter {
    width: 96%;
    padding-top: 0.5em;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--cardFooterBorder);

    .ExternalAppButton {
        width: 500px;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 1em;
    }

    .UnavailableAgencies {
        font-size: 0.8em;
        padding: 0.5em;
        text-align: center;
    }

    .ExitTapArea {
        flex: 0 1 100%;
        min-width: 120px;
        height: 80px;
    }
}