.CardRows {
    .RowArea {
        overflow: hidden;
        transition: height ease-in 200ms;
        width: 96%;
        margin: auto;

        /* Important padding safety area in case height is miscalculated */
        // padding-bottom: 0.75em;

    }

    &.collapsed {
        .CardRow-lastabovefold {
            border: 0;
        }
    }
}