.AgencyAlertCard {
    cursor: pointer;

    .AgencyAlert {
        display: flex;
        line-height: 1.5em;
        margin: 0.5em 0 0.5em 0;
        border-bottom: 1px solid #ddd;
        padding: 0.5em;
    }

    .AgencyAlert:last-child {
        border: none;
    }

    .AgencyAlert .AlertHeader {
        font-weight: 600;
        margin-bottom: 0.5em;
    }

    .AlertContent {
        flex: 0 1 100%;
    }

    .AlertIcon {
        flex: 0 1 0.5em;
        margin-right: 0.5em;
    }

    h2 {
        font-size: 1.2em;
        font-family: var(--primaryFontBold), "Arial Narrow", "Arial", sans-serif;
        font-weight: 500;
    }

    .AlertLink a {
        display: block;
        padding: 0.5em;
        border: 1px solid;
        border-radius: 0.75em;
        text-align: center;
        margin-top: 1em;
    }

    .ShowMore {
        margin-left: 1em;
        color: var(--secondaryText);
    }
}