.FavoriteButton {
    flex: 0 0 auto;

    .FavoriteTap {
        text-align: center;
        border-radius: 1em;
        display: block;
        margin-right: 0.5em;

        svg {
            width: 0.9em;
            height: 0.9em;
        }

    }

    // Favoriting is now triggered by a separate button not by itself
    display: none;

    &.is-favorite {
        display: block;
    }

}