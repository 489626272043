/* Note that special flex considerations are assigned in the component javascript itself */

.CardRow {
    /* Need to be pixels to do offsetHeight calculation */
    /* Only use padding because the tap area is clickable */
    border-bottom: 1px solid var(--cardBorder);
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    &:last-child {
        border: 0;
    }

    /* Each row is tap to expand */
    .TapArea {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* This inner class defines the flex layout interior of the card row columns */
    .RouteInfo {
        display: flex;
        align-items: center;
        min-height: 2.5em;
    }
}