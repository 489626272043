.CardTable {
	padding: 0.5em;
	table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
	}
	th {
		padding: 0.5em 0 0.5em 0;
	}
	td {
		width: 25%;
		padding: 0.5em 0 0.5em 0;
		text-wrap: balance;
		border-top: 1px solid var(--cardBorder);
	}
	tr {
		position: relative;
		transition: height ease-out 200ms;
	}
	tr.CardTableRowExpanded {
		overflow: hidden;
		background: var(--cardBackground);
		transition: all ease-out 150ms;
		height: 0px;
		td {
			display: none;
			column-span: all;
			border: none;
			width: 100%;
			padding: 0.5em;
		}
		&.active {
			height: 50px;
			td {
				display: table-cell;
			}
		}
	}
}
