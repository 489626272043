.ExitButton {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    outline: none;
    margin: 0;
    padding: 0.2em 0.2em 0 0;

    .icon {
        margin: 0.5em; // Gives plenty of tap area

        background: var(--exitButtonBackground);
        box-shadow: 0 0.2em 0.5em #333;

        border-radius: 2em;
        overflow: hidden;
        width: 1em;
        height: 1em;
        padding: 0.6em;

        svg {
            width: 100%;
            height: auto;

            * {
                stroke: var(--appHeaderBackground);
                stroke-width: 10;
            }
        }
    }

    &:hover,
    &:active {
        background: none;

        .icon {
            background: var(--buttonDestroy);
        }
    }
}