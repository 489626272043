.ExternalAppButton {

    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;

    // /* Must float it above any Tappable events */
    // position: relative;
    // z-index: 30;
    // font-family: var(--secondaryFontMedium);
    // font-weight: 600;
    // font-size: 0.8em;
    // text-transform: uppercase;
    // text-align: center;
    // text-decoration: none;
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 0.2em 0.5em 0.2em 0.5em;
    // margin: 0.5em 0 0.5em 0;
    // min-width: 5em;
    // min-height: 40px;
    // outline: none;
    // border: 1.5px solid var(--appButtonBorder);
    // color: var(--appButtonText);
    // border-radius: 1.5em;
    // background: none;

    // &:hover {
    //     background: var(--appButtonBackgroundHover);
    //     color: var(--appButtonTextHover);

    //     .highlight {
    //         stroke: var(--appButtonTextHover);
    //     }
    // }

    // svg {
    //     width: 1.2em;
    //     height: 1.2em;

    //     .highlight {
    //         stroke: var(--appButtonBorder);
    //     }
    // }
}