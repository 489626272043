.RouteAlert {
    padding-top: 0.5em;
    font-size: 1em;
    line-height: 1.5em;
    display: flex;

    .AlertIcon {
        flex: 0 1 0.5em;
        margin-right: 0.5em;
    }

    .AlertContent {
        flex: 0 1 100%;
    }

    .AlertDescription {
        font-size: 0.8em;
        line-height: 1.5em;
    }

    .AlertIcon img {
        width: 1.5em;
        height: auto;
    }
}