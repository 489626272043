.LocationSearchField {
    position: relative;
    display: flex;


    input {
        flex: 0 1 100%;
        display: block;
        color: var(--inputText);
        border: 1px solid var(--inputBorder);
        background: var(--inputBackground);
        padding: 0.5em;
        padding-left: 1em;
        height: 44px; // make it easier to tap this field
        border-radius: 0.2em;
        font: inherit;
        &:focus {
            border-radius: 0.5em 0.5em 0 0;
        }
        &.disabled {
            opacity: 0.25;
        }
        &.locked {
            padding-left: 0.5em;
            border: none;
            background: none;
        }
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'><path fill='%23222222' d='M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z'/></svg>");
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        // Hide the magnifying glass
        &::-webkit-search-decoration {
            display: none;
        }
    }

    // Similar styling to .LocationSearchDropdown .Result
    .select-item {
        border-top: 1px solid var(--appHeaderBorder);
        border-bottom: 1px solid var(--appHeaderBorder);
        display: block;
        width: 100%;
        text-align: left;
        padding: 1em;
    }

    .search-message {
        text-align: center;
        padding: 1em;
        color: var(--secondaryText);
        font-size: 0.9em;
    }
}
