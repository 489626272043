.TrafficMapboxCard {
    &.hidden {
        display: none;
    }

    text-align: left;

    h2 {
        font-size: 1.2em;
        font-family: var(--primaryFontBold), "Helvetica Neue", sans-serif;
        font-weight: 500;
    }

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        font: inherit;
        font-family: var(--secondaryFontMedium);
        font-size: 0.8em;
        margin: 0.5em 0 0.5em 0;
        padding-bottom: 0.5em;
        border-bottom: 2px solid #ddd;

        > div {
            flex: 0 1 100%;
            margin-right: 0.5em;

            &:first-child {
                margin-left: 0.5em;
            }

            &.route,
            &.prediction {
                text-align: center;
            }
        }
    }

    .row {
        display: flex;
        align-items: center;
        width: 100%;
        font: inherit;
        border-bottom: 1px solid #ddd;
        padding: 0.5em 0 0.5em 0;
        min-height: 2.5em;

        &:last-child {
            border: none;
        }

        > div {
            flex: 0 1 100%;
            margin-right: 0.5em;

            &:first-child {
                margin-left: 0.5em;
            }
        }

        .destination {
        }

        .route {
            display: flex;
            align-items: center;
            justify-content: center;

            .RouteIcon {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                padding: 0.8em 1em 0.6em 1em;
            }

            .label {
                font-family: var(--primaryFontBold);
                font-size: 1.2em;
                letter-spacing: 0.02em;
            }

            .label.text {
                font-size: 1em;
            }
        }

        .prediction {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--secondaryFontMedium);
            font-size: 1.3em;
        }

        .unit {
            font-weight: 100;
            font-size: 0.6em;
            margin-left: 0.5em;
        }
    }
}
