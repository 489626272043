.UpdateAnimation {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 0%;
    height: 5px;
    background: var(--uiActive);
    border-radius: 0 1em 1em 0;
    overflow: hidden;
    box-shadow: 0 0 2em var(--uiActive);
    opacity: 0;
    transition: opacity 250ms linear;
}

.UpdateAnimation.Stretch {
    opacity: 1;
    -webkit-animation: stretchBar 1.7s ease-out;
    -moz-animation: stretchBar 1.7s ease-out;
    animation: stretchBar 1.7s ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.UpdateAnimation.Stretch.Fade {
    opacity: 0 !important;
}

@keyframes stretchBar {
    0% {
        width: -10%;
    }

    60% {
        width: 90%;
    }

    90% {
        width: 100%;
    }

    100% {
        width: 100%;
    }
}

.ActiveWorkingIndicator {
    background: #fff;
    width: 100%;
    height: 100%;
    animation: colorBar 3s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes colorBar {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}
