.MenuWrapper {
    max-width: 800px; // Limits menu width for desktop
    margin: auto;
    transition: height 200ms ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 200;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid var(--appHeaderBorder);
}

/* In desktop the menu doesnt take over entire screen */
body.isDesktop .MenuWrapper {
    max-height: 80vh;

    &.expanded {
        box-shadow: 0 0.5em 1em var(--appHeaderBoxShadow);
    }
}

.AppHeader .MenuHeader {
    text-align: center;
    flex: 0 0 60px; // vertical flex of parent

    // Now set inner flex
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: relative;

    .heading {
        position: relative;
        line-height: 1.1em;
    }

    .AppLogo {
        flex: 0 0 3em;
        border-right: 0;
        margin: 0;
        padding: 0;
    }

    .ExitTapArea {
        flex: 0 0 2em;
        padding-right: 0.5em;

        svg {
            width: 100%;
            height: auto;
        }
    }
}