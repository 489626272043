.OpenMapButton {
    flex: 0 0 3em;

    .OpenMapButton-TapArea {
        display: block;
        width: 100%;
        text-align: center;

        .Icon {
            svg {
                * {
                    stroke: var(--uiIconColor);
                }
            }
        }

        &:hover,
        &:active {
            .Button {
                background: var(--appHeaderBackground);

                .injected-svg * {
                    stroke: var(--uiHoverColor);
                }
            }
        }
    }
}