.TripScreen {
    display: none; // TripScreen.js handles visibility

    position: fixed;
    left: 0;
    z-index: 1100;
    width: 100vw;

    // See fix for 100% height https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/ 
    min-height: 100vh;
    min-height: -webkit-fill-available;

    background: var(--appBackground);
    box-shadow: 0 -1em 3em var(--cardBoxShadow);

    bottom: -100vh; // Default, gets overrided by TripScreen.js for animating in
    transition: bottom 375ms ease-out;

    // Each screen is treated as its own scrollable container
    .screen-search {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: scroll;
        top: 0;
        z-index: 1; // Relative to parent

        background: var(--appBackground);
    }

    .screen-trip-plan {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: scroll;
        top: 0;
        z-index: 2; // Relative to parent

        left: 100vw;
        transition: left 250ms ease-in;

        background: var(--appBackground);

        box-shadow: 0 0 5em var(--cardBoxShadow);
    }

    .trip-footer {
        padding: 0.5em;

        .af-logo {
            text-align: center;
            font-size: 0.8em;
            display: flex;
            align-items: center;
            justify-content: center;

            .Icon {
                margin-left: 0.5em;

                svg {
                    width: auto;
                    height: 1.5em;
                }
            }
        }

        .version {
            color: #D4D9E6;
        }
    }
}

.isMobileSafari {
    .TripScreen {
        // For iOS notches
        margin-top: 11vh;
        height: 89vh;

        .screen-search {
            padding-bottom: 140px;
        }

        .screen-trip-plan {
            padding-bottom: 140px;
        }
    }
}

.isDesktop {
    .TripScreen {
        // Chrome doesnt ignore webkit fill
        min-height: 100vh;
    }
}