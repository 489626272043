/* App base reset styles ============================ */

* {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

div {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

html {
    background: var(--appBackground);
}

body {
    margin: 0;
    padding: 0;
    font: 400 100% / 1.2 var(--primaryFontRegular), "Helvetica Neue", sans-serif;
    color: var(--mainText);
    background: var(--appBackground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: manipulation;
}

button {
    font: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
    background: none;
    border: none;
    cursor: pointer;

    &:hover,
    &:active {
        color: var(--buttonTextHover);
        background: var(--buttonBackgroundHover);
    }
}

input {
    &:focus {
        outline: 2px solid var(--uiHoverBackground);
    }
}

body.scroll-locked {
    overflow: hidden;

    .ScrollArea {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4 {
    // Allow voiceover text to replace this field
    position: relative;
}

a {
    color: #1c99ef;
    border-color: #1c99ef;
}

a:hover {
    color: #e9632c;
}

// Svgs added by ReactSVG package have this className
.injected-svg {
    width: inherit;
    height: inherit;
}

// https://webaim.org/techniques/css/invisiblecontent/
.voiceover-text {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 1px;
    overflow: hidden;
    z-index: 100;
    opacity: 0;

    // Safari will actually size the tap area to the actual font size
    font-size: 1em !important;
    white-space: nowrap;

    &.voiceover-fill {
        width: 100%;
    }

    // Prevent Safari taparea text from overflowing
    &.voiceover-fit {
        display: block;
        max-width: 50px;
        max-height: 30px;
        word-break: keep-all;
        white-space: nowrap;
        overflow-wrap: break-word;
        text-overflow: clip;
    }

    .voiceover-hide {
        display: block;
        width: auto;
        height: 1px;
        overflow: hidden;
    }
}