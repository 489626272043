.CardHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;

    box-sizing: border-box;
    padding: 0.5em;

    /* Maintain height for Safari and tall enough for 1 line break for stop name */
    min-height: 3em;

    /* In card screen, capture height line breaks */
    flex: 0 0 auto;

    /* Just an overlay to tap on the entire thing */
    .CardHeader-TapArea {
        border-radius: 1em;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10; // Tap area now overrides any controls under
    }

    .column-left {
        display: flex;
        align-items: center;
        flex: 1 1 75%;
    }

    .column-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 0 1 50%;
    }

    .ExternalAppButton {
        flex: 0 0 80px;
    }

    .AgencyLogo {
        flex: 0 0 auto;
        margin-right: 0.25em;

        img {
            margin-top: 2px;
            width: auto;
            height: 1.25em;
            max-width: 4em;
        }
    }

    .ModeIcon {
        float: right;

        svg {
            width: auto;
            height: 1.75em;

            * {
                vector-effect: non-scaling-stroke;
                stroke-width: 1.4;
                stroke: var(--modeIconColor);
            }
        }
    }

    .StopName {
        flex: 0 1 auto;
        position: relative;

        h2 {
            font-size: 1.2em;
            font-family: var(--primaryFontBold), 'Arial Narrow', 'Arial',
                sans-serif;
            font-weight: 500; // Safari needs this at 500
        }
    }

    .Buttons {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0.25em;

    }
}

.noCardHeader .CardHeader {
    display: none;
}