.StaticHeader {
    display: flex;
    text-align: left;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 3px;

    .AppLogo {
        flex: 0 1 3em;
        font-weight: 600;
        margin-right: 0.5em;
        padding: 0.5em;
    }

    .AppLogo img {
        vertical-align: middle;
        width: 2.5em;
        height: 2.5em;
    }

    .Container {
        padding-left: 0.5em;
        display: flex;
    }

    .CustomerInfo {
        flex: 0 1 100%;
    }

    .CustomerInfo .container {
        display: inline-block;
        margin: 0.5em;
        padding: 0.5em;
    }

    .CustomerInfo img {
        display: inline-block;
        vertical-align: top;
        margin-right: 1em;
        width: auto;
        height: 2.5em;
        border-radius: 4px;
    }

    .address {
        font-size: 0.6em;
    }
}