/* Card is the container class */

.Card {
    position: relative;
    color: var(--cardText);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden; // required for slowMove

    &.hidden {
        display: none;
    }

    .OpenCardTapArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 1em;
        overflow: hidden;
        height: 100%;
        z-index: 100;
    }

    .WidgetContent {
        padding: 1em 0 1em 0;
    }

    /* Shadow chrome applied to card body now instead of the entire card */
    .CardBody {

        box-sizing: border-box;
        margin: 0 0.5em 1em 0.5em;

        background: var(--cardBackground);
        border-radius: 0.6em;
        box-shadow: 0 0.2em 0.2em var(--cardBoxShadow);

        @if var(--borderColor) {
            border: 1px solid var(--borderColor);
        }
    }

    &.favorite {
        .CardBody {
            border: 1px solid var(--secondaryText);
        }
    }

    &.noCardBorderRounding {
        .CardBody {
            border-radius: 0em;
        }
    }

    &.noCardShadow .CardBody {
        box-shadow: none;
    }
}

// body.isDesktop .Card.opened {
//     .CardHeader {
//         width: 92%;
//         padding: 1em;
//         font-size: 1.1em;
//     }
//     .Content {
//         font-size: 1.1em; // Puts it below the update animation
//     }
// }

// @media screen and (min-width: 800px) {
//     body.isDesktop .Card.opened .Content,
//     body.isTablet .Card.opened .Content {
//         max-width: 1000px;
//         max-height: 1000px;
//         overflow: hidden;
//     }
// }

.FavoriteMessage {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    width: 85%;
    font-size: 1.1em;
    border-radius: 1em;
    font-weight: bold;
    text-align: right;
    background: var(--cardBackground);
    overflow: hidden;
    text-align: center;
    padding: 0.75em;
    box-shadow: 0 0.2em 0.5em var(--cardBoxShadow);
}

.LogoCard {
    overflow: hidden;
    text-align: center;
    background: 0;
    border: none;
    box-shadow: none;

    img {
        max-width: 150px;
        max-height: 150px;
        width: auto;
        height: auto;
    }
}