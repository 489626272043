.TripOptions {
    padding: 1em;
    padding-bottom: 50px; // Scroll buffer


    .trip-group {
        margin-bottom: 2em;
        h3 {
            margin-bottom: 0.5em;
        }

        .trip-group-content {
            background: var(--cardBackground);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 0.5em;
        }
    }

}