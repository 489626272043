// Just a library of brand colors

// New 12-2022 https://www.figma.com/file/1KzREaLVvoXx50eknhyO5v/Actionfigure-Rebrand?t=qIc4YfOK1z9xk6MJ-0

$afOffWhite: #F6F6F4;
$afNewAsphalt: #373739;
$afReddish: #D95E4C;
$afNavy: #081754;
$afBlueberry: #2386ee;
$afLightBlueberry: #72B1F2;
$afDarkBlueberry: #0f67c2;

$afLightSnow: #fafcff;
$afSnow: #F3F3F3;
$afDarkSnow: #d3d9e7;

$afMint: #E6FFF6;

$afLightNavy: #2b3973;
$afNavy: #081754;
$afDarkNavy: #000724;

$afLightInk: #afb1bf;
$afInk: #606485;
$afDarkInk: #494e6d;

$afStrawberry: #fd4a5c;