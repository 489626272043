.MassTransitPredictions {

    .PredictionMinutesAway,
    .PredictionFrequency,
    .PredictionString {
        /* Safari has to use old version for flex-end */
        display: -webkit-box;
        -webkit-box-pack: end;
        -webkit-box-align: center;

        /* This goes after the above, Safari will ignore */
        display: flex;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
    }

    .PredictionMinutesAway {
        .Separator {
            flex: 0 1 4%;
            font-size: 1.3em;
        }

        .PredictionText {
            position: relative;
            flex: 0 1 40%;
            min-width: 1.5em;
            font-size: 1.3em;            
        }

        .Unit {
            margin-left: 0.5em;
            flex: 0 0 20%;
            font-weight: 100;
            font-size: 0.9em;
            font-family: var(--primaryFontRegular);
            color: var(--unitText);
        }
    }
    
    .actualAMPM {
        font-size: 0.75em;
        .Separator {
            flex: 0 1 40%;
            font-size: 1.3em;
            margin-top: 1em;
        }
        .PredictionText {
            word-wrap: break-word;

            min-width: 50px;
        }
    }

    .actual, .actual24 {
        @media (max-width: 800px) {
            font-size: 0.75em;
        }
        .Separator {
            flex: 0 0.4 40%;
            font-size: 1.3em;
        }
    }

    .PredictionString {
        min-width: 5em;
    }

    .PredictionFrequency {
        .PredictionText {
            position: relative;
            flex: 0 1 30%;
            min-width: 1.5em;
            font-size: 1.3em;
        }

        .PredictionPhrase {
            margin-left: 0.5em;
            flex: 0 1 50%;
            text-align: right;
        }

        .Unit {
            margin-left: 0.5em;
            flex: 0 0 20%;
            font-weight: 100;
            font-size: 0.7em;
            color: var(--secondaryText);
        }
    }
}
