.ExpandedInfo {
    transition: height 150ms ease-in;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ExpandedInfoInner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        text-align: right;

        .label {
            flex: 0 1 10%;
            color: var(--secondaryText);
            font-size: 0.6em;
            line-height: 1em;
        }

        .numbers {
            font-family: var(--secondaryFontMedium);
            font-size: 1.1em;

            span {
                margin-left: 0.5em;
            }
        }
    }

    .actualAMPM {
        .numbers {
            font-size: 0.9em;
        }
    }

    .actual,
    .actual24 {
        .numbers {
            font-size: 0.9em;
        }
    }
}