.Card.CommunityMessageCard {
    .Content {
        border: none;
        background: none;
        box-shadow: none;
        border-radius: 0;
        position: relative;
        margin: 1em auto 1em auto;
        border: 1px solid var(--cardBorder);
        box-sizing: border-box;
        overflow: hidden;
        width: 96%; // mimic margin/pad left right
    }

    .wrapper {
    }

    .Slide {
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .Title {
        color: #fff;
        background-color: #aaa;
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
        width: 100%;

        span {
            font-size: 1.4em;
            font-weight: bold;
        }

    }

    .Message {
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40%;
        width: 100%;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.99) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.99) 100%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.99) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);

        span {
            color: #223b47;
            padding: 0.2em 1em 1.5em 1em;
            font-weight: 500;
            font-size: 1.3em;
        }
    }

    .carousel .control-dots {
        margin: 0;

        .dot {
            background: var(--toggleBackground);
            box-shadow: none;
            width: 12px;
            height: 12px;
            margin: 0 8px 0 8px;
        }
    }
}