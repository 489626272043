.LocationWaypoint {
    display: flex;
    align-items: center;
    margin: 1em 0 0 0.5em;

    position: relative;
    box-sizing: content-box;
    z-index: 100;

    &:last-child {
        margin-top: 0;
        z-index: 90;
    }

    .location-icon {
        // position: absolute;
        top: 0.8em;
        left: 0.5em;
        z-index: 1;
        margin-right: 0.4em;

        svg {
            width: 1.3em;
            height: 1.3em;

            * {
                stroke: #fff;
                stroke-width: 1.5;
            }
        }

    }

    .location-label {
        padding-left: 30px;
        font-size: 0.9em;
        font-weight: 700;
        flex: 0 1 10%;
    }

    .location-name {
        flex: 0 1 100%;
        position: relative;

        .status {
            position: absolute;
            z-index: 10;
            top: 55px;
            left: 0.5em;
            background: var(--inputBackground);
            color: var(--mainText);
            width: 90%;
            opacity: 0;

            &.active {
                padding: 0.7em;
                top: 1px;
                opacity: 1;
                transition: opacity 100ms ease-in;
            }
        }
    }
}