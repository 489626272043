.StopAlert {
    display: flex;
    margin: 0.5em 0 0.5em 0;
    padding-top: 0.5em;
    border-top: 1px solid #ddd;
}

.StopAlert .AlertHeader {
    font-weight: bold;
}

.StopAlert .AlertIcon {
    margin-right: 0.5em;
}