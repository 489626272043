.NearbyCard {

    .Content {
        width: 90%; // simulate padding due to carousel width issues
        overflow: hidden;
        padding: 0;
        margin: 1em auto 1em auto;
        box-sizing: border-box;
    }

    .Title {
        font-family: var(--primaryFontBold), "Helvetica Neue", sans-serif;
        font-size: 1.2em;
        text-align: center;
        margin-top: 0.5em;
        border-bottom: 1px solid var(--cardHeaderBorder);
        padding-bottom: 0.5em;
    }

    .carousel {

        .slide {
            padding: 1em 0 2em 0;
        }

        .control-dots .dot {
            background: var(--toggleBackground);
            box-shadow: none;
            width: 12px;
            height: 12px;
            margin: 0 8px 0 8px;
        }
    }

    .Page {
        .Row {
            text-align: left;
            padding: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 1px solid var(--cardBorder);
            display: flex;

            &:last-child {
                border: none;
            }
        }

        .Icon svg {
            width: 1.5em;
            height: 1.5em;
            margin-right: 0.5em;
        }

        .Place {
            flex: 0 1 100%;
        }

        .PlaceName {
            font-weight: bold;
            font-size: 1.1em;
            letter-spacing: 0.2px;
        }

        .Navigate {
            flex: 0 1 auto;
            text-align: right;
            font-family: var(--secondaryFontMedium);
            font-size: 0.9em;
        }

        .Hours {
            margin-top: 0.25em;
            font-size: 1em;
        }

        .OpenMessage {
            font-weight: bold;
        }

        .LocationName {
            font: 200 0.9em / 1.5em var(--secondaryFontBold), Monospace, Courier;
        }
    }
}