.AppHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    color: var(--appHeaderText);
    background: var(--appHeaderBackground);
    box-shadow: 0 0 0.7em var(--appHeaderBoxShadow);
    min-height: 60px; // Height controlled in js

    .TapArea {
        z-index: 300;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
    }

    // Goes under the MenuHeader
    .TapArea.expanded {
        z-index: 100;
        width: 100%;
        height: 100%;
    }
}