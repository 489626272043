.VehicleColor {
    flex: 0 0 auto;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.4em;
    border-radius: 0.2em;
}

.noVehicleColor .VehicleColor {
    display: none;
}