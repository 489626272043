.MenuDropdown {
    flex: 0 1 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    text-align: center;

    // This dropdown is suppose to be different
    // than the header bar itself
    background: var(--menuBackground);
    color: var(--mainText);

    // Approx offset from top
    height: 80%;
    padding-bottom: 50px;

    .SavedLocations {
        width: 90%;
        margin: auto;
        margin-bottom: 2em;
        text-align: left;
    }

    .Settings {
        width: 90%;
        margin: auto;
        text-align: left;

        .CheckboxControl {
            display: inline-block;
            border: 1px solid var(--buttonBorder);
            padding: 0.5em;
            border-radius: 0.6em;
            color: var(--buttonText);
            background: var(--buttonBackground);

            &.selected {
                background: var(--buttonBorder);
            }

            input {
                margin-left: 0.5em;
                border: 1px solid var(--cmLightGray);
            }
        }
    }

    .text {
        padding: 0 0 1em 0;
    }

    .heading {
        font-size: 1.2em;
        font-family: var(--primaryFontBold);
        font-weight: 500;
        margin: 1em 0 0.5em 0;
        padding: 0;
    }

    ul {
        list-style: none;
        margin: auto;
        padding: 0;
    }

    li {
        display: block;
        position: relative;
    }

    .button {
        font: inherit;
        font-weight: bold;
        font-size: 1.1em;
        text-align: left;
        display: block;
        background: none;
        border: none;
        border-top: 1px solid var(--buttonBorder);
        width: 92%;
        padding: 4%;
        padding-top: 0.75em;
        padding-bottom: 0.75em;

        .address {
            margin-top: 0.2em;
            font-weight: 200;
            font-size: 0.8em;
        }
    }

    .button:hover {
        background: var(--buttonBackgroundHover);
        color: var(--buttonTextHover);
    }

    .return.button {
        border: 1px solid var(--buttonBorder);
        border-radius: 1em;
        width: auto;
        display: inline-block;
        padding: 0.5em 1em 0.5em 1em;
        margin: 0.5em auto 0.5em auto;
        text-align: center;
    }

    .delete.button {
        position: absolute;
        top: 1em;
        right: 0;
        width: 1em;
        height: 1em;
        padding: 0.2em;
        border: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        transition: opacity 200ms ease-in;
        border: 1px solid var(--appHeaderText);
        border-radius: 3em;

        svg {
            padding-top: 0.3em;
            width: 100%;
            height: auto;

            * {
                stroke: var(--appHeaderText);
            }
        }

        &.prompt {
            position: absolute;
            top: 1px;
            width: 98%;
            height: 100%;
            background: var(--appHeaderBackground);
            border: 0;
            border-radius: none;
            opacity: 0.9;
            white-space: pre;
        }

        .delete-tap-area {}

        .prompt-text {
            color: var(--tsWhite);
            background: var(--buttonDestroy);
            border-radius: 0.5em;
            padding: 0.5em;
            border: 1px solid var(--buttonBorder);
        }
    }
}
