.Debug {
    background: #fff;
    margin: 0;
    border-top: 1px solid #222;
    padding: 1em;
    word-break: break-all;
    text-overflow: word-wrap;

    button {
        text-align: center;
        width: 100%;
        border-radius: 0.5em;
        border: 1px solid #222;
        font-size: 1.2em;
        padding: 0.5em;
        display: block;
        margin: 0 0 0.5em 0;
        min-width: 250px;
    }
}