.TabBarButton {
    // Forces all to be equal width
    flex: 1 1 0px;
    overflow: hidden;
    text-align: center;
    height: 100%;

    background: var(--navBackground);

    &.tripplan {
        background-color: var(--navButtonAltBg);
    }

    span {
        display: block;

        &.text {
            font-size: 0.9em;
            font-family: var(--primaryFontRegular);
            color: var(--navButton);
        }
    }

    .icon {
        width: 30px;
        height: 30px;
        display: inline-block;

        svg {
            width: 30px;
            height: 30px;

            * {
                stroke: var(--navButton);
                vector-effect: non-scaling-stroke;
                stroke-width: 1;
            }
        }
    }

    &.tripplan {
        svg .st1tripplan {
            fill: var(--navButton);
        }
    }


    button {
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        width: 100%;
        height: 100%;
        outline: none;

        &:hover {
            background: none; // Disable default
        }

        position: relative;

        .selected-indicator {
            position: absolute;
            bottom: 0;
            left: 10%;
            margin: auto;
            overflow: hidden;
            border-radius: 5px 5px 0 0;
            width: 80%;
            height: 0px;
            background-color: var(--navBackground);
            transition: height 250ms linear;
        }
    }

    &.selected {
        .selected-indicator {
            height: 6px;
            background-color: var(--navButtonActiveBackground);
        }

        .text {
            color: var(--navButtonActiveText);
        }

        svg * {
            stroke: var(--navButtonActive);
        }
    }
}