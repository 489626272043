:root[color-mode="light"] {
    --mainText: #020202;
    --secondaryText: #373739;
    --fadedText: #B1B1B1;
    --unitText: #B1B1B1;
    --icons: #{$afBlueberry};

    // General App
    --appBackground: #{$afLightSnow};
    --appStatusBackground: #{$afLightSnow};

    --inputBackground: #fff;
    --inputBorder: #{$afDarkSnow};
    --inputText: #{$afDarkNavy};
    --submitBackground: #{$afBlueberry};
    --submitText: #{$afLightSnow};

    // Header 
    --appHeaderText: #{$afLightSnow};
    --appHeaderTextSecondary: #ccc;
    --appHeaderBackground: #{$afNavy};
    --appHeaderBorder: #{$afDarkSnow};
    --appHeaderBoxShadow: #{$afLightInk};

    // Menu is the dropdown from header
    // Different from param menu= 
    --menuBackground: #fff;

    // General UI 
    --modeIconColor: #{$afDarkNavy};
    --weatherIconColor: #{$afDarkNavy};
    --uiIconColor: #{$afNavy};
    --uiHoverBackground: #{$afNavy};
    --uiHoverColor: #{$afLightSnow};
    --uiActive: #{$afLightSnow};

    // Cards
    --cardText: #{$afDarkNavy};
    --cardBackground: #{$afOffWhite};
    --cardBoxShadow: #ccc;
    --cardBorder: #ccc;
    --cardHeaderBorder: #555555;
    --cardFooterBorder: #{$afDarkSnow};
    --cardFooterBackground: #{$afLightSnow};

    // Card Rows
    --tapHeaderHighlight: #{$afBlueberry};
    --tapRowHighlight: #{$afDarkSnow};
    --overlay: #{$afDarkInk};

    // Generic Buttons
    --buttonBackground: #{$afBlueberry};
    --buttonBorder: #{$afDarkSnow};
    --buttonText: #fff;
    --buttonTextHover: #{$afDarkNavy};
    --buttonBackgroundHover: #d2d2d2;
    --buttonShadow: #888;
    --buttonDestroy: #{$afLightBlueberry};
    --exitButtonBackground: #{$afBlueberry};

    --toggleBackground: #{$afDarkNavy};

    // External App Buttons
    --appButtonText: #{$afNavy};
    --appButtonBorder: #{$afNavy};
    --appButtonTextHover: #fff;
    --appButtonBackgroundHover: #{$afNavy};

    // Nav
    --navBackground: #{$afNavy};
    --navBorder: 'none';
    --navBoxShadow: #{$afLightInk};
    --navButton: #fff;
    --navButtonActiveText: #fff;
    --navButtonActive: #fff;
    --navButtonActiveBackground: #{$afBlueberry};
    --navButtonAltBg: #{$afBlueberry};

    // Map
    --mapMarkerBoxShadow: rgba(0, 0, 0, 0.5);

}