.CardRowRight {
    font-family: var(--primaryFontBold);
    flex: 1 1 30%;
    position: relative;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    .Crowding {
        flex: 1 1 auto;
        margin-right: 0.5em;

        display: flex;
        align-items: center;
        justify-content: flex-start; // Keep it left aligned in this expanded div
        .crowding-icon {
            flex: 0 0 auto;
            svg {
                width: auto;
                height: 1.5em;
                * {
                    fill: var(--secondaryText);
                }
            }
            &.empty {
                svg * {
                    opacity: 0.5;
                }
            }
        }
    }

    .MassTransitPredictions {
        flex: 0 1 auto; // Do not let it expand beyond what it needs
    }

    .WalkPredictions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .WalkPredictions .WalkMinutes {
        flex: 0 0 auto; // Never allow this to line break
        font-size: 1.3em;
        text-align: right;
        margin-right: 0.2em;
    }

    .WalkPredictions .Units {
        font-family: var(--primaryFontRegular);
        flex: 0 1 25%;
        min-width: 2.5em;
        font-size: 0.9em;
        margin-left: 0.2em;
        color: var(--unitText);
    }

    .MinAway {
        font-size: 0.9em;
        color: var(--unitText);
        text-align: left;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        font-family: var(--primaryFontRegular);

        .Prediction {
            font-family: var(--primaryFontBold);
            color: var(--mainText);
            margin-right: 0.2em;
            font-size: 1.5em; // We have to upscale font because of lokalise embedded text
            min-width: 1.2em; // So numbers line up to the right vertically
            text-align: right;
        }
    }

    .Scheduled {
        font-size: 0.9em;
        color: var(--fadedText);
        text-align: right;
    }

    .ParkingCost {
        white-space: nowrap;
        .prediction {
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            display: flex;
            .unit {
                margin-left: 0.5em;
                font-size: 0.8em;
            }
            &.unknown {
                font-size: 0.8em;
            }
        }
    }
}
