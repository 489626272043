.TripOption {

    header {
        display: flex;
        align-items: center;

        h3 {
            flex: 0 1 100%;
            text-align: left;
            padding: 0 0 0.2em 0.2em;
        }

        .Icon {
            flex: 0 1 20%;
            text-align: right;

            svg {
                width: auto;
                height: 1.5em;

                * {
                    stroke-width: 1.5;
                    stroke: var(--mainText);
                }
            }
        }
    }


    button {
        padding: 1.2em 1em 0.75em 1em;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--cardBorder);

        &:hover,
        &:active {
            background: var(--uiHoverColor);
        }

        .open-new-icon {
            position: absolute;
            top: 0.5em;
            right: 1em;
            width: 1em;
            height: 1em;
        }
    }

    button[disabled] {
        background: $afOffWhite;
        opacity: 0.4;
        cursor: default;
        mix-blend-mode: darken;
    }

    .mode-icon svg * {
        fill: var(--mainText);
    }

    &.recommended {
        h3 {
            color: $afBlueberry;
        }

        button {
            border-color: var(--mainText);
        }

        .recommended-flag {
            text-align: right;
            font-size: 0.8em;
            color: var(--fadedText);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0.5em;

            .Icon {
                margin-left: 0.5em;
            }
        }

    }


    .option-icons {
        display: flex;
        align-items: center;
        width: 100%;

        .option-icons--mini-trip {
            flex: 0 1 80%;
        }

        .option-icons--time {
            flex: 0 1 20%;
            text-align: right;
            width: 100%;
            min-width: 50px;

            .time {
                font-weight: bold;
            }

            .min {
                color: $afLightInk;
                font-size: 0.8em;
            }
        }
    }

}