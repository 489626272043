.LocationTitle {
    flex: 0 0 70%; // dont overrun weather
    margin: 0 0 0 5%;
    display: block;
    text-align: left;

    h1 {
        font-size: 1em;
        font-weight: 600;
    }

    /* LocationInput and StaticLocationName show virtually the same thing, location name
    * but for Accessibility compliance they are different tags
    */
    .LocationInput {
        border: 0;
        color: inherit;
        width: 99%;
        padding: 0.5em;
        font: inherit;
        font-size: 1.1em;
        font-weight: bold;
        outline: none;
        background: none;
        border-bottom: 1px solid var(--appHeaderBorder);

        &::placeholder {
            color: var(--appHeaderText);
        }
    }

    .StaticLocationName {
        width: 99%;
        padding: 0.5em;
        padding-left: 0;
        font: inherit;
        font-size: 1.1em;
        font-weight: bold;
        outline: none;

        .heading {
            display: flex;
            align-items: center;
        }

        .location-icon {
            flex: 0 0 1.3em;
            width: 1.3em;
            height: 1.3em;
            margin-right: 0.5em;

            svg {
                width: 100%;
                height: auto;
                vector-effect: non-scaling-stroke;

                * {
                    stroke-width: 1;
                    stroke: var(--appHeaderText);
                }
            }
        }

        .location-name {
            flex: 0 1 100%;
        }
    }
}