.Tester {
    font: 400 90%/1.2 "proxima_novamedium", "Helvetica Neue", Helvetica, sans-serif;
    overflow: hidden;
    position: relative;
    z-index: 200;
    max-width: 600px;
    margin: auto;
    padding: 1em;
    color: $afDarkNavy;
    background: $afOffWhite;

    ul {
        margin-left: 1em;
    }

    div {
        user-select: text;
    }

    .desc {
        text-align: center;
        padding-bottom: 2em;
    }

    h1 {
        color: $afDarkBlueberry;
        font-size: 1.2em;
        margin-bottom: 0.5em;
        text-align: center;

        &.page-title {
            color: $afNavy;
        }
    }

    h2 {
        font-size: 1.1em;
        margin: 1em 0 0.5em 0;
    }

    hr {
        border: 1px solid $afLightInk;
        margin: 2em 0 2em 0;
    }

    .button-group {
        display: flex;

        button {
            margin-right: 0.5em;

            &:last-child {
                margin: 0;
            }
        }
    }

    button {
        background: #fff;
        border: 1px solid #aaa;
        padding: 0.5em 0.5em 0.5em 1em;
        display: block;
        min-height: 44px;
        font-weight: 400;
        text-align: left;
        width: 100%;
        border-radius: 0.5em;

        &:hover,
        &:active,
        &.selected {
            background: $afLightBlueberry;
            font-weight: 500;
        }
    }

    .select-group {
        .title {
            text-align: center;
            margin-bottom: 5px;
        }

        width: 80%;
        margin: auto;

        button {
            min-height: 30px;
            margin: 0;
            font-weight: 400;
            border: 1px solid #ccc;
        }

        margin-bottom: 1.5em;
    }

    .qalink {
        position: relative;

        &::after {
            content: "->";
            font-weight: 800;
            position: absolute;
            right: 1em;
            top: 25%;
        }
    }

    ul {
        margin-bottom: 0.5em;
    }

    input[type="text"],
    select {
        cursor: pointer;
        border: 1px solid #888;
        font: inherit;
        width: 90%;
        padding: 0.5em;
        font-family: Courier, Monospace;
        background: #fff;
    }

    input[type="checkbox"] {
        padding: 1em;
        margin: 1em;
        border: 1px solid #ccc;
    }

    textarea {
        width: 100%;
        font-weight: 600;
        font-family: Courier, Monospace;
        min-height: 100px;
        font-size: 1em;
        padding: 0.5em;
        border: 1px solid #ccc;
    }

    .Submit {
        margin-top: 0.5em;
        height: 50px;
        border: 1px solid #ccc;
        width: 100%;
        border-radius: 2em;
        text-align: center;
        margin-bottom: 0.5em;
        padding: 0.5em;
        font-weight: 600;
        background: #1c99ef;
        color: #fff;
        cursor: pointer;
        outline: 0;
        letter-spacing: -0.5px;
        font-family: inherit;
        font-size: 1.2em;
    }

    .InlineSubmit {
        border: 1px solid #ccc;
        border-radius: 1em;
        height: 50px;
        margin-top: 0.2em;
        width: 100%;
        padding: 0.5em;
        background: #1c99ef;
        color: #fff;
        cursor: pointer;
        outline: 0;
        font-weight: bold;
    }

    .Submit:hover {
        background: #0033ef;
    }

    .Field {
        display: flex;
        align-items: center;

        label {
            flex: 1 0 150px;
            color: $afDarkBlueberry;
            padding-right: 0.5em;

            span {
                font-size: 0.8em;
                color: $afDarkNavy;
            }
        }
    }

    .Field.Dropdown {
        &.show-arrow {
            select {
                font-size: 0.8em;
                background: #eee;
            }
        }
    }

    .FieldGroup {
        margin-bottom: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px solid #ddd;
    }

    #errors.success {
        animation: greensplash 2s;
    }

    #errors.failure {
        animation: redsplash 2s;
    }

    @keyframes greensplash {
        0% {
            background: #eee;
        }

        70% {
            background: #00ff00;
        }

        100% {
            background: #eee;
        }
    }

    @keyframes redsplash {
        0% {
            background: #eee;
        }

        70% {
            background: #ff0000;
        }

        100% {
            background: #eee;
        }
    }

    .ActivationCodeInput {
        .code {
            margin-left: 1.5em;
        }
        .codeInput {
            border-radius: 0.5em;
            width: 80%;
            height: 2.5em;
            border: none;
            box-shadow: 0 0.3em 0.5em #ccc;
        }
        .urlInput {
            border-radius: 0.5em;
            width: 100%;
            height: 2.5em;
            border: none;
            box-shadow: 0 0.3em 0.5em #ccc;
        }
        .loadButton {
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: auto;
            align-items: center;
            // font-size: 1em;
            color: white;
            width: 60%;
            height: 2.5em;
            background: #2386EE;
            box-shadow: 0.706705px 1.41341px 3.53352px 1.41341px rgba(0, 0, 0, 0.16);
            border-radius: 7.35631px;
        }
        .error {
            border: solid #FD4A5C 1.54167px;
        }
    }

    .ActivationCode {
        .activation-container {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .customerName {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            .customerOptions {
                margin-left: 2em;
                width: 65%;
                display: flex;
                flex-direction: column;
            }      
            .customerDropdown {
                width: 100%;
                box-shadow: 0 0.3em 0.5em #ccc;
            }      
            .customerText {
                border-radius: 0.3em;
                width: 100%;
                height: 2.5em;
                border: none;
                margin-top: 1em;
                box-shadow: 0 0.3em 0.5em #ccc;
            }
        }                      

        .maxActivations {
            margin-top: 2em;
            display: flex;
            flex-direction: row;
            .maxActivationText {
                margin-left: 3.5em;
                border-radius: 0.3em;
                width: 40%;
                height: 2.5em;
                border: none;
                box-shadow: 0 0.3em 0.5em #ccc;
            }
        }

        .activationDate {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            .activationDateOptions {
                margin-left: 2em;
                width: 65%;
                display: flex;
                flex-direction: column;
            }
        }

        .deactivationDate {
            margin-top: 1em;
            margin-bottom: 2em;
            display: flex;
            flex-direction: row;
            .deactivationOptions {
                margin-left: 2em;
                width: 65%;
                display: flex;
                flex-direction: column;
            }
        }

        .dateDropdown {
            width: 35%;
            // box-shadow: 0 0.3em 0.5em #ccc;
        }

        .radio {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 50%;
        }

        .radio-button {
            margin-right: 0.5em;
        }

        .dateSelect {
            width: 50%;
            border: none;
            border-radius: 0.3em;
            height: 2.5em;
            box-shadow: 0 0.3em 0.5em #ccc;
        }  

        .submitActivationCode {
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: auto;
            align-items: center;
            font-size: 1.3em;
            color: white;
            width: 30%;
            height: 2.2em;
            background: #2386EE;
            box-shadow: 0.706705px 1.41341px 3.53352px 1.41341px rgba(0, 0, 0, 0.16);
            border-radius: 7.35631px;
        }

        .customerUrl {
            margin-top: 3em;
            .urlContainer {
                border-radius: 0.3em;
                width: 100%;
                height: 2.5em;
                border: none;
                box-shadow: 0 0.3em 0.5em #ccc;
            }
            .copyButton {
                cursor: pointer;
                display: flex;
                justify-content: center;
                margin: auto;
                align-items: center;
                // font-size: 1em;
                color: white;
                width: 60%;
                height: 2.5em;
                background: #2386EE;
                box-shadow: 0.706705px 1.41341px 3.53352px 1.41341px rgba(0, 0, 0, 0.16);
                border-radius: 7.35631px;
            }
        }

        .error {
            border: solid #FD4A5C 1.54167px;
        }

        .message {
            display: flex;
            justify-content: center;
            margin: auto;
            margin-top: 1em;
            align-items: center;
        }

        .success {
            color : #4BB543;            
        }
    }
}