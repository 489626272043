.TripPlanScreen {
    position: relative;
    background: var(--appBackground);

    .screen-header {
        position: sticky;
        top: 0;
        z-index: 50;
    }

    .body {
        .map {
            .Icon {
                svg {
                    width: 2.5em;
                    height: 2.5em;
                }
            }
        }

        .content {
            margin: auto;
            max-width: 500px;
            padding: 0.5em;

            h2 {
                margin-top: 1em;
                margin-bottom: 0.5em;
                font-size: 1.2em;
            }

            .button-group {
                width: 80%;
                margin: auto;
                margin-bottom: 2em;
            }
        }

    }

}