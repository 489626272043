.FlightCard {
  .CardTable.FlightTable {
    th {
      text-align: center;
    }
    th:first-child {
      text-align: left;
    }
    td.city {
      width: 20%;
      font-weight: bold;
      max-width: 100px;
    }
    td.info {
      font-size: 0.9em;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 1.5em;
          height: 1.5em;
          border-radius: 0.5em;
          margin: 0.5em 0.2em 0.5em;
        }
      }
    }
    td.status {
      font-size: 0.9em;
      text-align: center;
    }
    td.time {
      text-wrap: nowrap;
      font-weight: 500;
      font-size: 1.1em;
      text-align: center;
    }
  }
}
