.TripPromoCard {
    flex: 1 1 100%;

    display: block;
    width: 100%;
    text-align: center;

    h3 {
        margin-bottom: 0.5em;
    }
    .TapArea {
        color: var(--cardText);
        background: var(--cardBackground);
        box-shadow: 0 0.1em 0.5em var(--cardBoxShadow);

        max-width: 500px;
        width: 80%;
        margin: 0.5em auto 0.5em auto;
        padding: 1em;
        border-radius: 1em;

        &:hover {
            color: var(--buttonTextHover);
            background: var(--buttonBackgroundHover);
        }
    }
}
