.WeatherCard {
    padding: 0.5em;
    color: var(--mainText);
    font-family: var(--primaryFontRegular);

    .Blocks {
        display: flex;
    }

    .Forecast {
        text-align: center;
        flex: 0 1 100%;

        .header {
            border-bottom: 1px solid var(--cardBorder);
            padding-bottom: 0.5em;

            .Icon {
                border-radius: 2em;
                overflow: hidden;

                svg {
                    fill: var(--mainText);
                    width: 3em;
                    height: 3em;
                }
            }

            .Label {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 0.8em;
                min-height: 2.5em;
                overflow-wrap: anywhere;
            }
        }

        .content {
            margin-top: 0.5em;
            padding-left: 0.2em;
            text-align: center;
        }
    }

    .Time {
        font-size: 0.8em;
        font-weight: bold;
        font-family: var(--secondaryFontBold);
        margin-right: 0.5em; // centering
        margin-bottom: 0.5em;
    }

    .Temperature {
        flex: 0 0 100%;
        font-size: 1.1em;
    }
}