.SeeMore {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: var(--fadedText);
    border-radius: 0.5em;
    overflow: hidden;

    .Icon {
        margin-left: 0.5em;
        padding: 0.75em;

        svg {
            width: auto;
            height: 1em;

            * {
                stroke: var(--fadedText);
            }
        }
    }


    &:hover {
        background: var(--buttonBackgroundHover);
    }
}