.TrafficCard {
    &.hidden {
        display: none;
    }

    h2 {
        font-size: 1.2em;
        font-family: var(--primaryFontBold), "Helvetica Neue", sans-serif;
        font-weight: 500;
    }

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        font: inherit;
        font-family: var(--secondaryFontMedium);
        font-size: 0.8em;
        margin: 0.5em 0 0.5em 0;
        padding-bottom: 0.5em;
        border-bottom: 2px solid #ddd;
    }

    .header > div {
        flex: 0 1 100%;
    }

    .header .route {
        flex: 0 1 60%;
        text-align: center;
    }

    .header .prediction {
        text-align: center;
        flex: 0 1 50%;
    }

    .row {
        display: flex;
        align-items: center;
        width: 100%;
        font: inherit;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.5em;

        &:last-child {
            border: none;
        }

        > div {
            flex: 0 1 100%;
        }

        .route {
            text-align: center;
            flex: 0 1 60%;
            margin: 0.2em 0 0.2em 0;
        }

        .route .RouteIcon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 0.8em 1em 0.6em 1em;
        }

        .route .label {
            font-family: var(--primaryFontBold);
            font-size: 1.2em;
            letter-spacing: 0.02em;
            text-align: center;
        }

        .route .label.text {
            font-size: 1em;
            text-align: left;
        }

        .prediction {
            font-family: var(--secondaryFontMedium);
            font-size: 1.1em;
            flex: 0 1 50%;
            text-align: center;
        }

        .unit {
            font-weight: 100;
            font-size: 0.6em;
            color: var(--secondaryText);
        }
    }
}