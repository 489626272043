.StepList {
    padding: 0;
    margin: 1em 0 1em 0.5em;
    list-style: none;

    .StepList {
        margin: 0;
    }

    .Step {
        position: relative;
        padding: 0;
        margin-bottom: 0.5em;

        .container {
            display: flex;
            position: relative;
        }

        .step-expanded {
            position: relative;
        }

        .TapArea {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .step-line-dot-column {
            position: relative;
            flex: 0 0 1.75em;
            overflow: hidden;

            .Icon {
                position: absolute;
                z-index: 10;
                left: 5px;
                top: 0;

                &.stepEnd {
                    top: unset;
                    bottom: 11px;
                }

                svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 11px;
                    height: auto;
                }
            }

            .line {
                position: absolute;
                z-index: 1;
                height: 100%;
                min-height: 100px; // Mobile Safari needs this
                top: 0;
                left: 9.5px;
                width: 1.5px;
                border-left: 1.5px dashed var(--mainText);
            }

        }

        &.with-color {

            .step-line-dot-column {
                .line {
                    left: 8.5px;
                    top: 5px;
                    width: 3px;
                    height: 100%;
                    border: none;
                    background-color: var(--mainText);
                }
            }
        }

        .step-icon-column {
            flex: 0 0 3em;
            display: flex;
            align-items: center;

            .direction-icon {
                .Icon {
                    svg {
                        width: 2em;
                        height: 2em;

                        * {
                            stroke: var(--mainText);
                        }
                    }
                }
            }

            .mode-icon {
                .Icon {
                    svg {
                        width: 2em;
                        height: 1.5em;

                        * {
                            stroke: var(--mainText);
                        }
                    }
                }
            }
        }

        .step-info-column {
            margin-top: -0.2em; // Slightly align text above icons
            flex: 0 1 100%;
            padding: 1em 0 1em 0;

            font-size: 0.8em;

            .header {
                font-size: 1.2em;
            }

            .routes {
                margin: 1em 0 0 0;
                font-size: 1.3em;
                display: flex;
                align-items: center;

                >* {
                    margin-right: 0.5em;
                }

                .route-indicator {
                    width: 1.2em;
                    flex: 0 0 auto;
                    border-radius: 0.2em;
                }
            }
        }

        .step-expand-icon {
            flex: 0 0 2em;
            display: flex;
            align-items: center;

            svg {
                width: 1em;
                height: auto;
            }
        }

        .time {
            color: var(--secondaryText);
        }
    }
}