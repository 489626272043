.App {
    &.scroll-lock {
        position: absolute;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
    .overlay {
        position: fixed;
        opacity: 0;
        top: 0;
        left: 0;
        transition: opacity 250ms ease-out;
    }
    @if var(--zoom) {
        zoom: var(--zoom);
    }
}

body.scroll-lock {
    position: fixed; // Requires add'l JavaScript to maintain last scrolled position
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

// body.isMobileSafari {
//     .App {
//         position: absolute;
//         height: 100%;
//         width: 100%;
//         overflow-y: scroll;
//     }
// }

// This is for the card map popup
body.add-overlay .App .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: var(--overlay);
    opacity: 0.8;
}
