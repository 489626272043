.Capacity {
	flex: 0 0 auto;
	margin-right: 0.5em;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.VehicleCount {
		position: absolute;
		// Should inherit flex position from above
		width: 100%;
		font-family: var(--secondaryFontMedium);
	}

	svg circle:first-child {
		stroke-width: 1;
	}
}
