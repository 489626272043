.CardRowCenter {
    flex: 1 1 75%;
    display: flex;
    align-items: center;
    position: relative;
    word-wrap: break-word;
    color: var(--primaryFontRegular);

    /* Includes all vehicle information */
    .VehicleText {
        flex: 0 1 100%;
        padding: 0.5em;
        font-size: 1.1em;

        .VehicleDescription {
            p {
                // max-width: 120px;
            }
        }
    }

    /* Button is separate so it doesnt collide with text */
    .VehicleButton {
        flex: 0 1 auto;
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    .VehicleVia,
    .VehicleDirection,
    .VehiclePlatform,
    .VehicleInfo {
        font-size: 0.8em;
    }

    .surge {
        border: 1px solid var(--cardBorder);
        padding: 0.2em;
        margin-right: 1em;
    }

    .LocationName {
        font-weight: 600;
    }

    .LocationInfo {
        font-size: 0.9em;
    }
}